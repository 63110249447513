import React, { Component } from 'react';

export default class Resume extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="resume">
                <div className="row education">
                    <div className="three columns header-col">
                        <h1><span>Education</span></h1>
                    </div>
                    <div className="nine columns main-col">
                        {
                            resumeData.education && resumeData.education.map((item) => {
                                return (
                                    <div className="row item education-item">
                                        <div className="twelve columns">
                                            <h4>{item.UniversityName}</h4>
                                            <p className="info">
                                                {item.specialization}
                                                <span>&bull;</span>
                                                <em className="date">{item.MonthOfJoining} {item.YearOfJoining}
                                                    <text> - </text>
                                                </em>
                                                <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em></p>
                                            <p className="description">
                                                {item.Achievements}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="row work">
                    <div className="one column header-col work-header">
                        <h1><span>Work</span></h1>
                    </div>
                    <div className="twelve columns main-col">
                        {
                            resumeData.work && resumeData.work.map((item) => {
                                return (
                                    <div className="row item">
                                        <div className="timeline">
                                            <div className="entry">
                                                <div className="title">
                                                    <h3><em className="date">{item.MonthOfJoining} {item.YearOfJoining}<text> - </text></em>
                                                        <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em></h3>

                                                    <p className="info">{item.Location}</p>
                                                </div>
                                                <div className="body">
                                                    <h6 className="role">{item.CompanyName}</h6>
                                                    <p className="description">{item.Description}</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="row skill">
                    <div className="two columns header-col">
                        <h1><span>Skills</span></h1>
                    </div>

                    <div className="ten columns main-col">
                        {resumeData.skillset && resumeData.skillset.map((skill) => {
                            return (
                                <div className="three columns skill-item">
                                    <img src={skill.icon} alt={skill.skillname} style={{ maxHeight: '70px', marginRight: '10px' }} />
                                    <h5>{skill.skillname}</h5>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );;
    }
}
